import React from "react";

import Layout from "../components/layout";
import Home from "../components/home";

const IndexPage = ({ data }) => {
	return (
		<Layout>
			<Home />
		</Layout>
	);
};

export default IndexPage;
