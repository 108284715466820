import React from "react";
import styled from "styled-components";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const StyledSection = styled.section`
	.title {
		margin: 0;
	}
	.subtitle {
		margin-top: 0;
	}
	.highlighted {
		font-weight: 700;
		background-color: #cdf3e1;
	}
	.description {
		margin: 20px 0px;
	}
	.content-section {
		margin: 33px 0px;
	}
`;

const Home = () => (
	<StyledSection id="hero">
		<h1 className="title">
			Hi
			<span role="img" aria-label="emoji">
				{" 👋,"}
			</span>
		</h1>

		{/* Introduction Paragon */}
		<div className="content-section">
			<span>
				I'm a full stack software engineer based in NYC, interested in
				all things web, software, and UX. I'm a developer by trade, but
				really I just love building things with people, no matter the
				role.
			</span>
		</div>

		{/* "Currently" Bullets */}
		{/* <div className="content-section">
			<p>
				<mark className="highlighted">Currently</mark>
			</p>
			<ul>
				<li>
					Full stack developer at{" "}
					<OutboundLink
						href="https://www.withlantern.com"
						target="_blank"
						rel="noreferrer"
					>
						Lantern
					</OutboundLink>
					, building a post-sales CRM for bottom up SaaS companies
				</li>
				<li>
					Upgrading my frontend chops by finally learning TypeScript
				</li>
				<li>
					Dipping my toes into the future with Ethereum and Solidity
				</li>
			</ul>
		</div> */}

		{/* "Previously" Bullets */}
		<div className="content-section">
			<p>
				<mark className="highlighted">
					A few things I've helped build...
				</mark>
			</p>
			<ul>
				<li>
					<OutboundLink
						href="https://www.withlantern.com"
						target="_blank"
						rel="noreferrer"
					>
						Lantern
					</OutboundLink>{" "}
					- The worlds first sales & success platform centered around
					real time engagement
				</li>
				<li>
					<OutboundLink
						href="https://jeremybinder.dev/portfolio/measure"
						target="_blank"
						rel="noreferrer"
					>
						TryMeasure.com
					</OutboundLink>{" "}
					- A collaborative no-code dashboard tool for business
					intelligence (
					<OutboundLink
						href="https://measure.jeremybinder.dev/"
						target="_blank"
						rel="noreferrer"
					>
						demo
					</OutboundLink>
					)
				</li>
				<li>
					<OutboundLink
						href="https://github.com/FellowshipOfThePing/Corvallis-Off-Campus-Mobile"
						target="_blank"
						rel="noreferrer"
					>
						Corvallis Off Campus
					</OutboundLink>{" "}
					- A housing aggregator app for Oregon State University
					students to find apartments
				</li>
				<li>
					<OutboundLink
						href="https://github.com/FellowshipOfThePing/heyhue"
						target="_blank"
						rel="noreferrer"
					>
						HeyHue
					</OutboundLink>{" "}
					- A simple CLI for my Hue lights (I got tired of Siri taking
					too long)
				</li>
			</ul>
		</div>

		{/* "Free Time" Paragraph */}
		<div className="content-section">
			<mark className="highlighted">In my free time</mark>, I like to
			build things that abstract away the mindless/repetetive tasks every
			developer has to deal with. I maintain a{" "}
			<OutboundLink
				href="https://github.com/FellowshipOfThePing/j-utils"
				target="_blank"
				rel="noreferrer"
			>
				small library of CLI commands
			</OutboundLink>{" "}
			that keep me sane in the terminal, as well as a{" "}
			<OutboundLink
				href="https://github.com/FellowshipOfThePing/js-boilerplate"
				target="_blank"
				rel="noreferrer"
			>
				React/ESLint template
			</OutboundLink>{" "}
			that I can use to sync up any project to my styling preferences.
		</div>

		{/* "Before getting into CS" Paragraph */}
		<div className="content-section">
			<mark className="highlighted">Before getting into CS</mark>, I
			studied audio engineering and jazz drumset at Ithaca College, and
			interned at professional recording studios in Seattle and NYC, where
			I even had the privilege of playing a small role in recording a{" "}
			<OutboundLink
				href="https://en.wikipedia.org/wiki/U.F.O.F."
				target="_blank"
				rel="noreferrer"
			>
				grammy-nominated album.
			</OutboundLink>
		</div>

		{/* "Say hi" Section */}
		<div className="content-section">
			<mark className="highlighted">Say hi!</mark> You can DM me on{" "}
			<OutboundLink
				href="https://twitter.com/generictechbro"
				target="_blank"
				rel="noreferrer"
			>
				Twitter
			</OutboundLink>{" "}
			or send me an{" "}
			<OutboundLink href="mailto: jeremysbinder@gmail.com">
				email
			</OutboundLink>{" "}
			and I’ll get back to you asap.
		</div>
	</StyledSection>
);

export default Home;
